import { css, StyleSheet } from 'aphrodite';
import { interpolate, timer } from 'd3';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { breakpoints, Footer, Header, lightBlueGrey, stylesheet, viewportConstants as vc } from '../common/';
import { Results, Search } from '../search';

const tags = [
  'the economy',
  'immigration',
  'Trump',
  'Congress',
  'healthcare'
]

const styles = StyleSheet.create({
  header: {
    minHeight: 290,
    width: '100%',
  },
  headerContent: {
    padding: '30px 100px 30px 100px',
    ...breakpoints(vc.PHONE, {
      padding: 30
    })
  },
  tagline: {
    color: '#fff',
    fontSize: 36,
    display: 'block',
    width: '100%',
  },
  tag: {
    color: lightBlueGrey
  },
  inputContainer: {
    position: 'relative',
    marginTop: 20,
  },
  resultsArea: {
    minHeight: 'calc(100vh - 830px)'
  },
  resultsHeader: {
    marginLeft: '110px',
    ...breakpoints(vc.PHONE, {
      marginLeft: '25px'
    })
  },
  about: {
    color: 'rgba(152,162,175,1)',
    margin: '20px 0 10px 0',
    fontSize: '0.9em',
    fontWeight: 300,
    width: '50%',
    ...breakpoints(vc.PHONE, { width: '100%' }),
    ...breakpoints(vc.TABLET_PORTRAIT, { width: '70%' }),
    ...breakpoints(vc.BIG_DESKTOP, { width: '25%' })
  },
  a: {
    color: 'rgba(152,162,175,1)',
    textDecoration: 'underline',
    ':hover': {
      color: '#fff'
    }
  }
})

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      tag: tags[0],
      index: 0
    }

  }

  componentDidMount() {
    this.props.onComponentDidMount();

    this.interval = setInterval(() => {
      const index = this.state.index < tags.length - 1 ? this.state.index + 1 : 0;
      this.setState({
        index,
        tag: tags[index],
        opacity: 0
      })

      const i = interpolate(0, 1);
      const t = timer((elapsed) => {

        const duration = 750;
        this.setState({
          opacity: i(elapsed / duration)
        })

        if (elapsed > duration) {
          t.stop();
          this.setState({
            opacity: i(1)
          })
        }
      });


    }, 3000)
  }

  render() {
    return (
      <div className={css(stylesheet.root)}>
        <div className={(css([stylesheet.primaryBlueBackground, styles.header]))}>
          <Header />
          <div className={css(styles.headerContent)}>
            <div className={css(styles.tagline)}>Find out what Americans think about <span className={css(styles.tag)} style={{ opacity: this.state.opacity }}>{this.state.tag}</span></div>
            <div className={css(styles.inputContainer)}>
              <Search mixPanelOrigin={'home'} headerHeight={400} searchOnMount={true} />
              <div className={css(styles.about)}>
                Learn more about <a className={css(styles.a)} href="/what-we-offer">research with Civiqs</a>.
              </div>
            </div>
          </div>
        </div>
        <div className={css(styles.resultsArea)}>
          <div className={css(styles.resultsHeader)}>
            <h2 style={{ margin: '20px 0 5px 0' }}>Survey Results</h2>
            <div style={{ fontSize: '0.8em' }}>
              Click on any chart to view detailed information.
            </div>
            <span style={{ color: 'rgba(152,162,175,1)', fontSize: '0.8em' }}>
              Updated {moment.tz('America/Los_Angeles').format('MMMM D, YYYY')} •
              <a style={{ color: 'rgba(152,162,175,1)', textDecoration: 'none' }} href="/faq"> FAQ</a>
            </span>
          </div>
          <Results mixPanelOrigin={'home'} />
        </div>
        <Footer />
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }
}

Home.propTypes = {
  onComponentDidMount: PropTypes.func
}

Home.contextTypes = {
  isMobile: PropTypes.bool
}

export default Home;
